import router from './router';

/**
 * Raphal utils
 */
const raphalUtils = {
  install: function (_Vue) {
    if (!_Vue.prototype.$raphalUtils) {
      _Vue.prototype.$raphalUtils = {
        projectsCriteria: 'total',
        projectsCriteriaAdmin: 'total',
        lastActiveRouteName: ''
      };
    }

    _Vue.prototype.$raphalUtils.noScore = '--';

    /**
     *
     * @param {String|Object} option
     */
    _Vue.prototype.$raphalUtils.goto = function (option, resolve) {
      if (['string', 'object'].indexOf(typeof(option)) >= 0) {
        router.push(option).then(() => {
          resolve();
        }).catch(() => {});
      }
    }

    /**
     *
     * @param {String} score
     * @param {String} key
     * @param {String} unit
     * @returns {String}
     */
    _Vue.prototype.$raphalUtils.getScoreByKey = function (key, score, unit) {
      const noScore = this.noScore;
      if (typeof(score) === 'undefined') {
        return noScore;
      }
      const scores = JSON.parse(score);
      return ((key in scores) && (scores[key] !== noScore)) ? (unit ? `${scores[key]} ${unit}` : scores[key]) : noScore;
    }

    /**
     * Check if a certain variable is a number
     * @param {*} num
     * @returns {Boolean}
     */
    _Vue.prototype.$raphalUtils.isNumber = function (num) {
      return !isNaN(Number(num));
    }

    /**
     * Parse mobile scores
     * @param {String} score
     * @param {Boolean} parseForCsv
     * @returns {Object}
     */
    _Vue.prototype.$raphalUtils.parseMobileScores = function (score, parseForCsv=false) {
      const scores = JSON.parse(score);
      const isNumber = this.isNumber;
      const noScore = this.noScore;
      let globalScore = isNumber(scores.global) ? Number(scores.global) : (isNumber(scores.globalScore) ? Number(scores.globalScore) * 100 : noScore);
      let accuracyScore = noScore;
      if (isNumber(scores.precision)) {
        accuracyScore = Number(scores.precision).toFixed(1);
      } else if (isNumber(scores.accuracyValue)) {
        accuracyScore = (Number(scores.accuracyValue)*100).toFixed(1);
      } else if (isNumber(scores.accuracyScore)) {
        accuracyScore = Number(scores.accuracyScore).toFixed(1);
      }
      let speedScore = noScore;
      if (isNumber(scores.vitesse)) {
        speedScore = Number(scores.vitesse).toFixed(1);
      } else if (isNumber(scores.speedValue)) {
        speedScore = Number(scores.speedValue).toFixed(1);
      } else if (isNumber(scores.speedScore)) {
        speedScore = Number(scores.speedScore).toFixed(1);
      }
      const matchingScore = isNumber(scores.scoreMatching) ? (Number(scores.scoreMatching)*100).toFixed(0) :
        (isNumber(scores.matchingScore) ? (Number(scores.matchingScore)*100).toFixed(0) : noScore);
      const rtkScore = isNumber(scores.pourcentRtk) ? (Number(scores.pourcentRtk)*100).toFixed(0) :
        (isNumber(scores.rtkScore) ? (Number(scores.rtkScore)*100).toFixed(0) : noScore);
      if (parseForCsv) {
        if (globalScore !== noScore) {
          globalScore = (globalScore).toFixed(0);
          scores.globalScore = [{
            value: 100.0 - (globalScore),
            name: '2'
          },{
            value: Number(globalScore),
            name: '3'
          }];
        } else {
          scores.globalScore = [{
            value: 100.0,
            name: '2'
          },{
            value: 0,
            name: '3'
          }];
        }
      } else {
        scores.globalScore = (globalScore === noScore) ? globalScore : `${globalScore.toFixed(1)} %`;
      }
      ["global", "precision", "vitesse", "scoreMatching", "pourcentRtk"].forEach(k => delete scores[k]);
      scores.accuracyScore = (accuracyScore === noScore) ? accuracyScore : `${accuracyScore} cm`;
      scores.speedScore = (speedScore === noScore) ? speedScore :`${speedScore} m/s`;
      scores.matchingScore = (matchingScore === noScore) ? matchingScore : `${matchingScore} %`;
      scores.rtkScore = (rtkScore === noScore) ? rtkScore :`${rtkScore} %`;
      return scores;
    }

    /**
     *
     * @param {String} score
     * @param {String} key
     * @returns {String}
     */
    _Vue.prototype.$raphalUtils.getMobileScoreByKey = function (key, score) {
      const noScore = this.noScore;
      if (typeof(score) === 'undefined') {
        return noScore;
      }
      const scores = this.parseMobileScores(score);
      return (key in scores) ? scores[key] : noScore;
    }

    /**
     * Format insee code to 5 digits
     * @param {Number} value
     */
    _Vue.prototype.$raphalUtils.formatInsee = function (value) {
      let code = '. . .';
      if (typeof(value) !== 'undefined') {
        const m = 5;
        code = value;
        const n = `${code}`.length;
        if (n < m) {
          code = `${'0'.repeat(m-n)}${code}`;
        }
      }
      return code;
    }

    /**
     * Get the 3D status associated with the processing status value
     * @param {Number} value 
     * @returns {String}
     */
    _Vue.prototype.$raphalUtils.get3DStatus = function(value) {
      const options = ['En Attente', 'En cours', 'Complet', 'Incomplet'];
      const n = options.length;
      if ((typeof(value) === 'number' ) && (value >= 0) && (value <= n - 1)) {
        return options[value];
      }
      return 'Echec';
    }

    /**
     * Get ionospheric text corresponding to value
     * @param {Number} value 
     * @returns {String}
     */
    _Vue.prototype.$raphalUtils.getIonoText = function(value) {
      let res = 'Indisponible';
      if ((typeof(value) === 'undefined') || (value === -1.0)) {
        return res;
      } else if (value < 4.5) {
        res = 'Faible';
      } else if (value >= 4.5 && value <= 5.5) {
        res = 'Moyen';
      } else if (value > 5.5) {
        res = 'Haute';
      }
      return res;
    }

    /**
     * Get project state for a given project (0 if project 'failed' and 1 if 'ongoing')
     * @param {Object} data
     * @returns {Number}
     */
    _Vue.prototype.$raphalUtils.getProcessingStatus = function(data) {
      let res = 0;
      if ((typeof(data) === 'object') && (data.etatReceptionPhotos !== 0) && (data.etatPreTraitement < 3) && (data.etatTraitement < 3)) {
        res = 1;
      }
      return res;
    }

    /**
     * Get project state label for a given project
     * @param {Object} data 
     * @returns {String}
     */
    _Vue.prototype.$raphalUtils.getProjectState = function(data) {
      let res = '';
      if (typeof(data) === 'undefined') {
        return res;
      }
      if (data.etatGeoref === 4) {
        res = 'Archivé';
      }
      if (data.etatReceptionPhotos === 0) {
        res = 'Echec de la réception';
      } else {
        if (data.etatPreTraitement === 0) {
          res = 'Pré-traitement en attente';
        } else if (data.etatPreTraitement === 1) {
          res = 'Pré-traitement en cours';
        } else if (data.etatPreTraitement >= 3) {
          res = 'Echec';
        } else {
          if (data.etatTraitement === 0) {
            res = 'Calcul 3D en attente';
          } else if (data.etatTraitement === 1) {
            res = 'Calcul 3D en cours';
          } else if (data.etatTraitement >= 3) {
            res = 'Echec';
          } else if (data.etatTraitement === 2) {
            res = 'Exploitable';
          }
        }
      }
      return res;
    }

    /**
     * Parse list of projects
     * @param {Array} data
     * @returns {Array}
     */
    _Vue.prototype.$raphalUtils.parseProjectsList = function(data) {
      if (typeof(data) === 'undefined') {
        return [];
      }
      return data.edges.map((e) => e.node);
    }

    /**
     * Get the page title
     * @param {Boolean} isBookmark
     * @returns {String}
     */
    _Vue.prototype.$raphalUtils.getPageTitle = function(isBookmark) {
      let title = "PROJETS";
      if (isBookmark) title += " SUIVIS";
      return title;
    }

    /**
     * Parse the statistics data for a given project
     * @param {String} filter
     * @returns {Object}
     */
    _Vue.prototype.$raphalUtils.parseProjectStats = function(filter) {
      if (typeof(filter) === 'undefined') {
        return {
          archived: 0,
          controle: 0,
          echec: 0,
          validate: 0,
          traite: 0,
          termine: 0,
          deleted: 0,
          bookmarked: 0,
          total: 0
        };
      }
      return JSON.parse(filter);
    }

    /**
     * Get bgcard classes
     * @param {Object} data
     * @returns {String}
     */
    _Vue.prototype.$raphalUtils.getBgcardClasses = function(data) {
      let classes = 'bg-raphal-total';
      if (typeof(data) === 'undefined') {
        return classes;
      }
      const { etatGeoref, etatPreTraitement, etatTraitement, etatReceptionPhotos, georeferenced, digitalised, premiumProject } = data;
      if (parseInt(etatGeoref) === 4) {
        classes = 'bg-raphal-archiver ';
      } else if (premiumProject === 0 && etatPreTraitement < 3 && etatTraitement < 3 && etatReceptionPhotos !== 0) {
        classes = 'bg-raphal-valider';
      } else if (parseInt(etatGeoref) < 4 && (georeferenced === false || digitalised === false) && etatReceptionPhotos !== 0) {
        classes = 'bg-raphal-traiter text-black-1';
      } else if (georeferenced === true && digitalised === true && parseInt(etatGeoref) !== 4) {
        classes = 'bg-raphal-termine';
      }
      return classes;
    }

    /**
     * Get icon color classes
     * @param {Object} data
     * @returns {String}
     */
    _Vue.prototype.$raphalUtils.getIconColorClasses = function(data) {
      let classes = 'border text-white';
      if (typeof(data) === 'undefined') {
        return classes;
      }
      const { etatPreTraitement, etatTraitement, etatReceptionPhotos, georeferenced, precisionGeoref } = data;
      if (
        !(etatPreTraitement < 3 && etatTraitement < 3 && etatReceptionPhotos < 3)
      ) {
        classes = 'border-2 shadow-echec border-raphal-echec';
      }
      if (georeferenced == true && precisionGeoref > 0.2) {
        classes = 'border-2 shadow-controler border-raphal-controler';
      }

      return classes;
    }

    /**
     * Get iono color classes
     * @param {Number} value
     * @returns {String}
     */
    _Vue.prototype.$raphalUtils.getIonoColorClasses = function(value) {
      let classes = 'text-raphal-echec';
      if (typeof(value) === 'undefined') {
        return classes;
      }
      if (value === -1.0) {
        classes = 'text-raphal-indisponible';
      } else if (value < 4.5) {
        classes = 'text-raphal-valider';
      } else if (value >= 4.5 && value <= 5.5) {
        classes = 'text-raphal-controler';
      }
      return classes;
    }

    /**
     * Check if the project type is 'easyscale' (or 'ar')
     * @param {Object} projectData
     * @returns {Boolean}
     */
    _Vue.prototype.$raphalUtils.isEasyscale = function(projectData) {
      return !projectData.rtk && projectData.nbBalises === 0 && projectData.indicateurBalise === 2;
    }

    /**
     * Check if LAZ export tool is disabled
     * @param {Object} projectData
     * @returns {Boolean}
     */
    _Vue.prototype.$raphalUtils.isLazDisabled = function(projectData) {
      return !projectData || projectData.premiumProject != 1 || projectData.etatGeoref < 1 || projectData.needGeorefUpdate != 0;
    }

    /**
     * Check if Ortho/Tif export tool is disabled
     * @param {Object} projectData
     * @returns {Boolean}
     */
    _Vue.prototype.$raphalUtils.isTifDisabled = function(projectData) {
      return !projectData || projectData.premiumProject != 1 || projectData.etatGeoref < 1 || projectData.needGeorefUpdate != 0;
    }

    /**
     * Check if Digit export tool is disabled
     * @param {Object} projectData
     * @returns {Boolean}
     */
    _Vue.prototype.$raphalUtils.isDigitDisabled = function(projectData) {
      return !projectData || projectData.premiumProject != 1 || projectData.etatGeoref < 3 || projectData.needGeorefUpdate != 0;
    }

    /**
     * Check if Pdf report tool is disabled
     * @param {Object} projectData
     * @returns {Boolean}
     */
    _Vue.prototype.$raphalUtils.isReportDisabled = function(projectData) {
      return !projectData || projectData.premiumProject != 1 || projectData.etatTraitement != 2 || projectData.needGeorefUpdate != 0;
    }

    /**
     * Check if Purchase is disabled
     * @param {Object} projectData
     * @returns {Boolean}
     */
    _Vue.prototype.$raphalUtils.isPurchaseDisabled = function(projectData) {
      return !projectData || !(
        projectData.premiumProject != 1
        && projectData.etatReceptionPhotos == 1
        && projectData.etatTraitement == 2
        && projectData.etatPreTraitement == 2
      );
    }

    /**
     * Check if Export tool is disabled
     * @param {Object} projectData
     * @returns {Boolean}
     */
    _Vue.prototype.$raphalUtils.isExportDisabled = function(projectData) {
      return !projectData || projectData.premiumProject != 1 || projectData.needGeorefUpdate != 0;
    }

    /**
     * Show alert message
     * @param {String} msg
     */
    _Vue.prototype.$raphalUtils.alert = function(msg) {
      if (window.ToastAlert) {
        window.ToastAlert.fire({
          title: msg
        })
      } else {
        alert(msg);
      }
    }

    /**
     * Show confirm message
     * @param {String} msg
     * @param {Function} callback
     */
    _Vue.prototype.$raphalUtils.confirm = function(msg, callback=function(){}) {
      if (window.ToastConfirm) {
        window.ToastConfirm.fire({
          title: msg
        }).then((result) => {
          if (result.isConfirmed) {
            callback();
          }
        });
      } else {
        if (confirm(msg)) {
          callback();
        }
      }
    }
  }
}

export default raphalUtils;
